import React, { useState } from 'react';
// import { useMediaQuery } from 'react-responsive';
import Card from './Card';
import {Pagination, Form} from 'react-bootstrap';


const CardList = ({ data }) => {
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedScope, setSelectedScope] = useState('All');
  const cardsPerPage = 4; // Nombre de cartes à afficher par page

  // Fonction de filtrage des cartes en fonction du scope
  const filterCardsByScope = (scope) => {
    if (scope === 'All') {
      return data;
    } else {
      return data.filter((item) => item.scope === scope);
    }
  };

  // Calcul du numéro de la dernière carte de la page en cours
  const indexOfLastCard = currentPage * cardsPerPage;
  // Calcul du numéro de la première carte de la page en cours
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // Sélection des cartes pour la page en cours en fonction du scope
  const currentCards = filterCardsByScope(selectedScope).slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="card-container">
      {/* Liste déroulante pour filtrer par scope */}
      <Form.Select
        value={selectedScope}
        onChange={(e) => setSelectedScope(e.target.value)}
      >
        <option value="All">Tout voir</option>

        <option value="Frontend">Frontend</option>
        <option value="Backend">Backend</option>
        <option value="Design UI">Design UI</option>
        <option value="Programmation">Programmation</option>
        <option value="Marketing Digital">Marketing Digital</option>
        {/* Ajoutez d'autres options en fonction de vos données */}
      </Form.Select>

      {/* Affichage des cartes de la page en cours en fonction du scope */}
      <div className='row d-flex flex-row justify-content-around'>
      {currentCards.map((item, index) => (
            <div className='col-md-6 col-lg-3 d-flex justify-content-center'>
                <Card
                key={index}
                title={item.name}
                content={item.structure}
                year={item.year}
                mobileContent={item.scope}
                />
            </div>
        
      ))}
      </div>

      {/* Pagination */}
      
      <div className="pagination-container d-flex justify-content-center">
        <Pagination>
          {filterCardsByScope(selectedScope).length > cardsPerPage &&
            Array.from({
              length: Math.ceil(
                filterCardsByScope(selectedScope).length / cardsPerPage
              ),
            }).map((item, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
        </Pagination>
      </div>
    </div>
  );
};

export default CardList;



