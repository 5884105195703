import React, { useState } from 'react';

import { Pagination } from 'react-bootstrap';
import ProjectCard from './projectCard';

const ProjectList = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(1);

  // Calcul du numéro de la dernière carte de la page en cours
  const indexOfLastCard = currentPage * cardsPerPage;
  // Calcul du numéro de la première carte de la page en cours
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // Sélection des cartes de la page en cours
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="card-container">
      {/* Affichage des cartes de la page en cours */}
      {currentCards.map((item, index) => (
        <ProjectCard
          key={index}
          name={item.name}
          image={item.image}
          link={item.link}
          description={item.description}
        />
      ))}

      {/* Centrer le composant de pagination */}
      <div className="pagination-container d-flex justify-content-center">
        <Pagination>
          {data.length > cardsPerPage &&
            Array.from({
              length: Math.ceil(data.length / cardsPerPage),
            }).map((item, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
        </Pagination>
      </div>
    </div>
  );
};

export default ProjectList;
