import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

const ProjectCard = ({ name, image, link, description }) => {
  return (
    <BootstrapCard className='d-flex col-md-6' style={{'min-height': '5rem' }} >
      <BootstrapCard.Body>
        <BootstrapCard.Img style={{height: '50%'}} src={image}/>
        <BootstrapCard.Title><h3>{name}</h3></BootstrapCard.Title>
        <BootstrapCard.Link className="btn btn-custom" href={link}>Visiter</BootstrapCard.Link>
        <div dangerouslySetInnerHTML={{ __html: description}}></div>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default ProjectCard;