import React, { useState } from 'react';
//import axios from 'axios';

function Contact() {


    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        message: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://backend.kilianduclos.com/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          setFormData({
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            message: ''
          });
          alert('Votre message a bien été envoyé!');
          // Handle successful form submission
        })
        .catch(error => {
          console.error('Erreur lors de la soumission du formulaire:', error);
          // Handle form submission error
        });
      };
    
    

    return (
        <section id='contact'className="bg-invert contact">
        <div className="container-flex">

        <h2>
            Contact
        </h2>

        <form action="" id="contact-form" onSubmit={handleSubmit} method='POST'>

            <div className="row">
            <div className="col-md-6">
                    <label for="nom">Prénom *</label>
                    <input className="form-control" type="text" id="name_from" name="firstname" value={formData.firstname} onChange={handleChange} placeholder="Votre nom"/>
                    <p className="comments">

                    </p>
                </div>  
                <div className="col-md-6">
                    <label for="nom">Nom *</label>
                    <input className="form-control" type="text" id="name_from" name="lastname" value={formData.lastname} onChange={handleChange} placeholder="Votre nom"/>
                    <p className="comments">

                    </p>
                </div>  
                <div className="col-md-6">
                    <label for="mail">Email *</label>
                    <input className="form-control" type="email" id="email_from" name="email" value={formData.email} onChange={handleChange} placeholder="Votre e-mail"/>
                    <p className="comments">

                    </p>
                </div> 
                <div className="col-md-6">
                    <label for="phone">Téléphone</label>
                    <input className='form-control' type="phone" id="phone_from" name="phone" value={formData.phone} onChange={handleChange} placeholder="votre téléphone" />
                    <p className="comments"></p>
                </div>
                <div className="col-md-12">
                    <label for="message">Message *</label>
                    <textarea className="form-control" id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Votre message"></textarea>
                    <p className="comments">

                    </p>
                </div>  
                <div className="col-md-12">
                    * Ces informations sont requises
                </div>
                <div className="col-md-12">
                    <input type="submit" className="btn btn-custom col-12" value="Envoyer" />
                </div>
                    
            </div>

        </form>
        <p>

        </p>
        </div>
    </section>
    )
}

export default Contact