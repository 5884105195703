import React from 'react';
import SkillCard from './skillCard'

const SkillList = ({ data }) => {
    return (
        <div className='row d-flex flex-row justify-content-center'>
          {data.map((item, index) => (
            <SkillCard
              key={index}
              title={item.name}
              logo={item.logo}
            />
          ))}
        </div>
      );
    };
export default SkillList;