import React, { useState, useEffect } from 'react';
import CardList from './certificationsTools/CardList';


const Certification = () => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    // Fonction pour récupérer les données depuis l'API
    const fetchData = async () => {
      try {
        const response = await fetch('https://backend.kilianduclos.com/api/certification');
        const data = await response.json();
        setCardData(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données depuis l\'API:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section id="certification" className="Certification container-flex">
      <h2>Certifications</h2>
      <CardList data={cardData} />
    </section>
  );
};

export default Certification;