import photo from '../assets/img/me.jpg'
import cv from '../assets/doc/cv.pdf'

export default function Home() {
  return (
    <section id="home">
       <img src={photo} className="intro-img" alt="Unplugged"/>
       <h1 className="intro-heading">Kilian Duclos</h1>
       <a className="intro-resume btn btn-custom" href={cv}>Télécharger CV</a>
    </section>
  );
};