import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

const SkillCard = ({ title, logo }) => {
  return (
    <BootstrapCard className='d-flex col-md-6' style={{width: '10rem', height: '10rem'}} >
      <BootstrapCard.Body>
        <BootstrapCard.Img style={{height: '50%'}} src={logo}/>
        <BootstrapCard.Title>{title}</BootstrapCard.Title>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default SkillCard;