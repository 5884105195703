import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

const Card = ({ title, content, year }) => {
  return (
    <BootstrapCard style={{width: '18rem'}} >
      <BootstrapCard.Body>
        <BootstrapCard.Title>{title}</BootstrapCard.Title>
        <BootstrapCard.Text>{content}</BootstrapCard.Text>
        <BootstrapCard.Text>{year}</BootstrapCard.Text>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default Card;