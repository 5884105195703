import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function NavBar() {
    return (
    <Navbar sticky='top' expand="lg" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="#homebp" ><h1>KD</h1></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#homebp">Accueil</Nav.Link>
              <Nav.Link href="#skillbp">Compétences</Nav.Link>
              <Nav.Link href='#certificationbp'>Certifications</Nav.Link>
              <Nav.Link href='#projectbp'>Projet</Nav.Link>
              <Nav.Link href='#contactbp'>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
}

