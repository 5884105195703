import logo from './logo.svg';
import './App.css';
import './assets/style/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Certification from './components/Certifications';
import Skill from './components/Skill'
import Project from './components/Project';
import Contact from './components/Contact';
import Footer from './components/Footer';
import NavBar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className='breakpoint' id='homebp'/>
      <Home />
      <div className='breakpoint' id='skillbp'/>
      <Skill />
      <div className='breakpoint' id='certificationbp'/>
      <Certification />
      <div className='breakpoint' id='projectbp'/>
      <Project />
      <div className='breakpoint' id='contactbp'/>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
